import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Carousel,
  Dropdown,
  Form,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Result,
  Select,
  Spin,
  Table,
  Tag,
  Upload,
  message,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  API_URL,
  AllMarketPlace,
  DefaultPerPage,
  MarketplaceFlag,
  SwapCommaAndDot,
  checkPermission,
  numberformat,
  timeSince,
} from "../../../config";
import AddItemModal from "./lib/import";
import { Wrapper } from "./style";
import Icons from "../../../components/icons";
import Config from "./lib/config";
import Pagination from "../../../components/pagination";
import { TableLoading } from "../../../components/table-animation";
import { GlobalContext } from "../../../commonContext";
import ChildUpdate from "./lib/child-update";
import styled from "styled-components";
import { Icon } from "@iconify/react/dist/iconify.js";

const { Dragger } = Upload;

const config = {
  title: "Amazon",
  center: true,
  content: (
    <>
      <span>Are you sure to upload the Catalog data to Amazon?</span>
    </>
  ),
};

const Catalogue = (props) => {
  const {
    GetListCatalogAction,
    UpdateCatalogConfigAction,
    GetListCatalogConfigAction,
    fakeActionCatalog,
    CreateFeedListingLogs,
    DeleteCatalogManualAction,
    SalesChannelListAction,

    fakeActionAnalytics,
    GetCategoryAction,
    GetSubCategoryAction,
  } = props;
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [importView, setImportView] = useState(false);
  const contextValue = useContext(GlobalContext);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterData, setFilterData] = useState({});

  const [configList, setConfigList] = useState([]);
  const [configLoading, setConfigLoading] = useState(true);

  const [updateLoading, setUpdateLoading] = useState(false);

  const [columnsList, setColumnsList] = useState([]);
  const [columnsListChild, setColumnsListChild] = useState([]);

  const [counts, setCounts] = useState({
    parent_count: 0,
    child_count: 0,
  });

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
    account_type: null,
  });
  const [childASINUpdateFlag, setChildASINUpdateFlag] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const [uploadLoading, setUploadLoading] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(true);

  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLoading, setSubCategoryLoading] = useState(false);

  const [selectedCategorys, setSelectedCategorys] = useState({
    category: null,
    subCategory: null,
  });

  const CatalogResponse = useSelector(
    (state) => state.Catalog.CatalogResponse || {}
  );
  const GetCatalogConfigResponse = useSelector(
    (state) => state.Catalog.GetCatalogConfigResponse || {}
  );
  const UpdateCatalogConfigResponse = useSelector(
    (state) => state.Catalog.UpdateCatalogConfigResponse || {}
  );
  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  const UpdateCatalogManualResponse = useSelector(
    (state) => state.Catalog.UpdateCatalogManualResponse || {}
  );
  const CreateFeedListingLogsResponse = useSelector(
    (state) => state.Catalog.CreateFeedListingLogsResponse || {}
  );
  const DeleteCatalogManualResponse = useSelector(
    (state) => state.Catalog.DeleteCatalogManualResponse || {}
  );
  const GetCategoryListResponse = useSelector(
    (state) => state.Analytics.GetCategoryListResponse || {}
  );
  const GetSubCategoryListResponse = useSelector(
    (state) => state.Analytics.GetSubCategoryListResponse || {}
  );

  console.log(DeleteCatalogManualResponse, "DeleteCatalogManualResponse");

  const [sign, setSign] = useState("$");
  useEffect(() => {
    if (CreateFeedListingLogsResponse?.status === true) {
      setUploadLoading(false);
      setSelectedRowKeys([]);
      message.success(CreateFeedListingLogsResponse?.message || "");
      fakeActionCatalog("CreateFeedListingLogsResponse");
    } else if (CreateFeedListingLogsResponse?.status === false) {
      setUploadLoading(false);
      setSelectedRowKeys([]);
      message.error(CreateFeedListingLogsResponse?.message || "");
      fakeActionCatalog("CreateFeedListingLogsResponse");
    }
  }, [CreateFeedListingLogsResponse]);
  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      // setMarketplaceList(SalesChannelListResponse?.data?.records || []);
      if (SalesChannelListResponse?.data?.records?.length > 0) {
        const salesList = SalesChannelListResponse?.data?.records?.reduce(
          (acc, e) => {
            const regionIndex = acc.findIndex(
              (r) => r.label === e.region_label
            );
            if (e?.default_marketplace === 1) {
              GetCategoryAction({
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
              GetSubCategoryAction({
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
              setMarketplaceSelected({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e.account_type,
              });
              setLoading(true);
              GetListCatalogAction({
                page: page,
                perPage: pageSize,
                ...filterData,
                ...{
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                },
              });
            }
            if (regionIndex === -1) {
              acc.push({
                label: e.region_label,
                options: [
                  {
                    label: (
                      <>
                        <img
                          src={`/domainImage/${e?.marketplace_flag}.png`}
                          style={{ height: "20px" }}
                          loading="lazy"
                          className="me-3"
                          onError={(e) => {
                            e.target.src = "/media/domainImage/red-flag.png";
                          }}
                        />
                        {e?.region_label === "Ecommerce"
                          ? "Ecommerce"
                          : `Amazon.${e?.account_type || ""}.${
                              e?.short_name || ""
                            }`}
                      </>
                    ),
                    value: e.marketplace_id,
                    region: e.region,
                    marketplace_id: e?.marketplace_id,
                    marketplace: e?.marketplace,
                    sales_channel: e?.sales_channel,
                    account_type: e.account_type,
                  },
                ],
              });
            } else {
              acc[regionIndex].options.push({
                label: (
                  <>
                    <img
                      src={`/domainImage/${e?.marketplace_flag}.png`}
                      style={{ height: "20px" }}
                      loading="lazy"
                      className="me-3"
                      onError={(e) => {
                        e.target.src = "/media/domainImage/red-flag.png";
                      }}
                    />
                    {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                  </>
                ),
                value: e.marketplace_id,
                marketplace_id: e?.marketplace_id,
                region: e.region,
                marketplace: e?.marketplace,
                sales_channel: e?.sales_channel,
              });
            }
            return acc;
          },
          []
        );

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);
        setMarketplaceLoading(false);
        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);
        setMarketplaceList([]);
      }
      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);
      setLoading(false);
      setList([]);
      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);
  useEffect(() => {
    if (GetCatalogConfigResponse?.status === true) {
      if (
        GetCatalogConfigResponse?.data?.records &&
        GetCatalogConfigResponse?.data?.records?.config
      ) {
        setConfigList(
          JSON.parse(GetCatalogConfigResponse?.data?.records?.config)
        );
      }
      setConfigLoading(false);
      fakeActionCatalog("GetCatalogConfigResponse");
    } else if (GetCatalogConfigResponse?.status === false) {
      setList([]);
      setConfigLoading(false);
      fakeActionCatalog("GetCatalogConfigResponse");
    }
  }, [GetCatalogConfigResponse]);
  useEffect(() => {
    if (GetCategoryListResponse?.status === true) {
      setCategoryLoading(false);
      setCategoryList(
        GetCategoryListResponse?.data?.data?.map((d) => ({
          label: d?.category,
          value: d?.category,
        }))
      );
      fakeActionAnalytics("GetCategoryListResponse");
    } else if (GetCategoryListResponse?.status === false) {
      setCategoryList([]);
      setCategoryLoading(false);
      message.destroy();
      message.warning(GetCategoryListResponse?.message);
      fakeActionAnalytics("GetCategoryListResponse");
    }
  }, [GetCategoryListResponse]);
  useEffect(() => {
    if (GetSubCategoryListResponse?.status === true) {
      setSubCategoryLoading(false);
      setSubCategoryList(
        GetSubCategoryListResponse?.data?.data?.map((d) => ({
          label: d?.sub_category,
          value: d?.sub_category,
        }))
      );
      fakeActionAnalytics("GetSubCategoryListResponse");
    } else if (GetSubCategoryListResponse?.status === false) {
      setSubCategoryList([]);
      setSubCategoryLoading(false);
      message.destroy();
      message.warning(GetSubCategoryListResponse?.message);
      fakeActionAnalytics("GetSubCategoryListResponse");
    }
  }, [GetSubCategoryListResponse]);
  useEffect(() => {
    if (DeleteCatalogManualResponse?.status === true) {
      message.destroy();
      setLoading(true);
      GetListCatalogAction({
        page: page,
        perPage: pageSize,
        ...marketplaceSelected,
        ...filterData,
      });
      message.success(DeleteCatalogManualResponse?.message || "Deleted");
      fakeActionCatalog("DeleteCatalogManualResponse");
    } else if (DeleteCatalogManualResponse?.status === false) {
      message.destroy();
      message.success(DeleteCatalogManualResponse?.message || "Deleted");
      fakeActionCatalog("DeleteCatalogManualResponse");
    }
  }, [DeleteCatalogManualResponse]);
  useEffect(() => {
    if (CatalogResponse?.status === true) {
      setCounts(
        CatalogResponse?.data?.asin_count || { parent_count: 0, child_count: 0 }
      );

      setTotalPage(CatalogResponse?.data?.pagination?.totalCount || 0);
      setList(CatalogResponse?.data?.records);
      setLoading(false);
      setSign(CatalogResponse?.data?.currency || "$");
      fakeActionCatalog("CatalogResponse");
    } else if (CatalogResponse?.status === false) {
      setList([]);
      setLoading(false);
      fakeActionCatalog("CatalogResponse");
    }
  }, [CatalogResponse]);
  useEffect(() => {
    if (UpdateCatalogManualResponse?.status === true) {
      setUpdateLoading(false);
      setLoading(true);
      setChildASINUpdateFlag(false);
      setSelectedRow({});
      GetListCatalogAction({
        page: page,
        perPage: pageSize,
        ...marketplaceSelected,
        ...filterData,
      });
      message.destroy();
      message.success(UpdateCatalogManualResponse?.message);
      fakeActionCatalog("UpdateCatalogManualResponse");
    } else if (UpdateCatalogManualResponse?.status === false) {
      const errors = Object.entries(
        UpdateCatalogManualResponse?.error || {}
      )?.[0];

      setUpdateLoading(false);
      message.destroy();
      message.error(
        UpdateCatalogManualResponse?.error
          ? errors?.[1]?.[0]
          : UpdateCatalogManualResponse?.message
      );
      fakeActionCatalog("UpdateCatalogManualResponse");
    }
  }, [UpdateCatalogManualResponse]);
  const CatalogManualMappingResponse = useSelector(
    (state) => state.Catalog.CatalogManualMappingResponse || {}
  );

  useEffect(() => {
    if (CatalogManualMappingResponse?.status === true) {
      message.destroy();
      message.success(CatalogManualMappingResponse?.message || "-");
      setLoading(true);
      GetListCatalogAction({
        page: page,
        perPage: pageSize,
        ...marketplaceSelected,

        ...filterData,
      });
      fakeActionCatalog("CatalogManualMappingResponse");
      setChildASINUpdateFlag(false);
      setUpdateLoading(false);
      setImportView(false);
    } else if (CatalogManualMappingResponse?.status === false) {
      message.destroy();
      setUpdateLoading(false);
      message.error(CatalogManualMappingResponse?.message || "-");
      fakeActionCatalog("CatalogManualMappingResponse");
    }
  }, [CatalogManualMappingResponse]);

  const formatData = (data) => {
    return data?.map((item) => {
      const formattedItem = {};

      for (const key in item) {
        if (item[key] === null || item[key] === "") {
          formattedItem[key] = "-";
        } else {
          formattedItem[key] = item[key];
        }
      }

      return formattedItem;
    });
  };

  useEffect(() => {
    // setLoading(true);
    // GetListCatalogAction({
    //   page: page,
    //   perPage: pageSize,
    //   ...marketplaceSelected,
    //   ...filterData,
    // });

    setConfigLoading(true);
    GetListCatalogConfigAction();

    setMarketplaceList([]);
    setMarketplaceLoading(true);
    SalesChannelListAction();
    return () => {};
  }, []);

  const onPageNo = (e) => {
    setPage(e);
    setLoading(true);
    GetListCatalogAction({
      page: e,
      perPage: pageSize,

      ...marketplaceSelected,
      ...filterData,
    });
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    GetListCatalogAction({
      page: page,
      perPage: e,
      ...marketplaceSelected,
      ...filterData,
    });
  };

  const columns = [
    // {
    //   title: "ID",
    //   // dataIndex: "id",
    //   // key: "id",
    //   width: 60,
    //   render: (_, __, i) => {
    //     return <span>{(page - 1) * pageSize + 1 + i}</span>;
    //   },
    // },
    // {
    //   title: "Main Image URL",
    //   dataIndex: "main_image_url",
    //   key: "main_image_url",
    // },

    {
      title: "Parent SKU",
      dataIndex: "sku",
      key: "sku",
      width: 100,
      render: (e) => {
        return <Tag color="lime">{e}</Tag>;
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      width: 150,
    },
    {
      title: "Image",
      width: 100,
      render: (text) => {
        return (
          <div style={{ width: "90px", height: "90px" }}>
            <div>
              <img
                style={{
                  width: "90px",
                  height: "90px",
                  objectFit: "contain",
                }}
                onError={(e) =>
                  (e.target.src =
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png")
                }
                src={text?.main_image_url}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 100,
      render: (e) => {
        return <Tag color="purple">{e}</Tag>;
      },
    },
    {
      title: "Sub-Category",
      dataIndex: "sub_category",
      key: "sub_category",
      width: 150,
      render: (e) => {
        return <Tag color="purple">{e}</Tag>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (e) => {
        if (e === "-") return <Tag color="red-inverse">{e}</Tag>;
        return <>{e === "Attivo" ? "✅" : "⚠️"}</>;
      },
    },

    {
      title: "EAN",
      dataIndex: "ean",
      key: "ean",
      width: 100,
      render: (e) => {
        return <Tag color="geekblue">{e}</Tag>;
      },
    },

    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: 100,
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      width: 200,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Product Title",
      dataIndex: "product_title",
      key: "product_title",
      width: 200,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 100,
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              parseFloat(e === "-" ? 0 : e || 0).toLocaleString(),
              sign,
              "",
              marketplaceSelected?.marketplace_id?.split("_")?.[0]
            )}
          </span>
        );
      },
    },
    {
      title: "Product Cost",
      dataIndex: "product_cost",
      key: "product_cost",
      width: 150,
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              parseFloat(e === "-" ? 0 : e || 0).toLocaleString(),
              sign,
              "",
              marketplaceSelected?.marketplace_id?.split("_")?.[0]
            )}
          </span>
        );
      },
    },

    {
      title: "Relationship Type",
      dataIndex: "relationship_type",
      key: "relationship_type",
      width: 180,
    },
    {
      title: "Variation Theme",
      dataIndex: "variation_theme",
      width: 180,
      key: "variation_theme",
    },

    {
      title: "Product Description",
      dataIndex: "product_description",
      key: "product_description",
      width: 200,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Bullet Point 1",
      dataIndex: "bullet_point_1",
      key: "bullet_point_1",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Bullet Point 2",
      dataIndex: "bullet_point_2",
      key: "bullet_point_2",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Bullet Point 3",
      dataIndex: "bullet_point_3",
      key: "bullet_point_3",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Bullet Point 4",
      dataIndex: "bullet_point_4",
      key: "bullet_point_4",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Bullet Point 5",
      dataIndex: "bullet_point_5",
      key: "bullet_point_5",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Style Name",
      dataIndex: "style_name",
      key: "style_name",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Color Name",
      dataIndex: "color_name",
      key: "color_name",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Size Name",
      dataIndex: "size_name",
      key: "size_name",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Material Name",
      dataIndex: "material_name",
      key: "material_name",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Flavor Name",
      dataIndex: "flavor_name",
      key: "flavor_name",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Website Shipping Weight",
      dataIndex: "website_shipping_weight",
      key: "website_shipping_weight",
      render: (e) => {
        return numberformat(e);
      },
      width: 250,
    },
    {
      title: "Item Length",
      dataIndex: "item_length",
      key: "item_length",
      width: 200,
      render: (e) => {
        return numberformat(e);
      },
    },
    {
      title: "Item Width",
      dataIndex: "item_width",
      key: "item_width",
      width: 200,
      render: (e) => {
        return numberformat(e);
      },
    },
    {
      title: "Item Height",
      dataIndex: "item_height",
      key: "item_height",
      width: 200,
    },
    {
      title: "Volume/Capacity Name",
      dataIndex: "volume_capacity_name",
      key: "volume_capacity_name",
      width: 220,
    },
    {
      title: "Ingredients",
      dataIndex: "ingredients",
      key: "ingredients",
      width: 200,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      width: 200,
    },
    {
      title: "Marketplace ID",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      width: 100,
    },
    // {
    //   title: "Created By",
    //   dataIndex: "created_by",
    //   key: "created_by",
    //   width: 120,
    // },
    // {
    //   title: "Updated By",
    //   dataIndex: "updated_by",
    //   key: "updated_by",
    //   width: 120,
    // },
    {
      title: "Created At",
      align: "left",
      width: 180,
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(text.created_at * 1000)).format(
                "MM/DD/YYYY hh:mm A"
              )}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(text.created_at)})
            </span>
          </div>
        );
      },
    },

    {
      title: "Updated At",
      align: "left",
      width: 180,
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(parseInt(text.updated_at) * 1000)).format(
                "MM/DD/YYYY hh:mm A"
              )}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(parseInt(text.updated_at))})
            </span>
          </div>
        );
      },
    },
  ];
  const columnsChild = [
    {
      title: "",

      width: 50,
      render: (_, __, i) => {
        return (
          <div className="d-flex align-items-center">
            <div>
              <span
                onClick={() => {
                  setChildASINUpdateFlag(true);
                  setSelectedRow(__);
                }}
                className="cursor-pointer svg-icon svg-icon-primary svg-icon-1"
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                    fill="currentColor"
                  />
                  <path
                    d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                    fill="currentColor"
                  />
                  <path
                    d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
            <div className="ms-3">
              <Popconfirm
                title="Are you sure to delete this catalog?"
                placement="left"
                cancelText="No"
                okText="Yes"
                onConfirm={() => {
                  DeleteCatalogManualAction(__?.id);
                  message.destroy();
                  message.loading("Loading...", 0);
                }}
              >
                <Icon
                  className="ms-3"
                  style={{ color: "#ff6c6c", cursor: "pointer" }}
                  width={24}
                  icon="material-symbols:delete"
                />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
    {
      title: "Child SKU",

      width: 150,
      render: (_, __, i) => {
        return <span>{_?.sku}</span>;
      },
    },
    {
      title: "Child ASIN",

      width: 100,
      render: (_, __, i) => {
        return <span>{_?.asin}</span>;
      },
    },

    {
      title: "Image",
      width: 100,
      render: (text) => {
        return (
          <div style={{ width: "90px", height: "90px" }}>
            <div>
              <img
                style={{
                  width: "90px",
                  height: "90px",
                  objectFit: "contain",
                }}
                onError={(e) =>
                  (e.target.src =
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png")
                }
                src={text?.main_image_url}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 100,
      render: (e) => {
        return <Tag color="purple">{e}</Tag>;
      },
    },
    {
      title: "Sub-Category",
      dataIndex: "sub_category",
      key: "sub_category",
      width: 150,
      render: (e) => {
        return <Tag color="purple">{e}</Tag>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (e) => {
        if (e === "-") return <Tag color="red-inverse">{e}</Tag>;
        return <>{e === "Attivo" ? "✅" : "⚠️"}</>;
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      width: 100,
      render: (e) => {
        return <Tag color="lime">{e}</Tag>;
      },
    },
    {
      title: "EAN",
      dataIndex: "ean",
      key: "ean",
      width: 100,
      render: (e) => {
        return <Tag color="geekblue">{e}</Tag>;
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      width: 100,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: 100,
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      width: 200,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Product Title",
      dataIndex: "product_title",
      key: "product_title",
      width: 200,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 100,
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              parseFloat(e === "-" ? 0 : e || 0).toLocaleString(),
              sign,
              "",
              marketplaceSelected?.marketplace_id?.split("_")?.[0]
            )}
          </span>
        );
      },
    },
    {
      title: "Product Cost",
      dataIndex: "product_cost",
      key: "product_cost",
      width: 150,
      render: (e) => {
        return (
          <span>
            {SwapCommaAndDot(
              parseFloat(e === "-" ? 0 : e || 0).toLocaleString(),
              sign,
              "",
              marketplaceSelected?.marketplace_id?.split("_")?.[0]
            )}
          </span>
        );
      },
    },

    {
      title: "Relationship Type",
      dataIndex: "relationship_type",
      key: "relationship_type",
      width: 180,
    },
    {
      title: "Variation Theme",
      dataIndex: "variation_theme",
      width: 180,
      key: "variation_theme",
    },

    {
      title: "Product Description",
      dataIndex: "product_description",
      key: "product_description",
      width: 200,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Bullet Point 1",
      dataIndex: "bullet_point_1",
      key: "bullet_point_1",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Bullet Point 2",
      dataIndex: "bullet_point_2",
      key: "bullet_point_2",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Bullet Point 3",
      dataIndex: "bullet_point_3",
      key: "bullet_point_3",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Bullet Point 4",
      dataIndex: "bullet_point_4",
      key: "bullet_point_4",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Bullet Point 5",
      dataIndex: "bullet_point_5",
      key: "bullet_point_5",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Style Name",
      dataIndex: "style_name",
      key: "style_name",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Color Name",
      dataIndex: "color_name",
      key: "color_name",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Size Name",
      dataIndex: "size_name",
      key: "size_name",
      // width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Material Name",
      dataIndex: "material_name",
      key: "material_name",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Flavor Name",
      dataIndex: "flavor_name",
      key: "flavor_name",
      width: 150,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Website Shipping Weight",
      dataIndex: "website_shipping_weight",
      key: "website_shipping_weight",
      render: (e) => {
        return numberformat(e);
      },
      width: 250,
    },
    {
      title: "Item Length",
      dataIndex: "item_length",
      key: "item_length",
      width: 200,
      render: (e) => {
        return numberformat(e);
      },
    },
    {
      title: "Item Width",
      dataIndex: "item_width",
      key: "item_width",
      width: 200,
      render: (e) => {
        return numberformat(e);
      },
    },
    {
      title: "Item Height",
      dataIndex: "item_height",
      key: "item_height",
      width: 200,
    },
    {
      title: "Volume/Capacity Name",
      dataIndex: "volume_capacity_name",
      key: "volume_capacity_name",
      width: 220,
    },
    {
      title: "Ingredients",
      dataIndex: "ingredients",
      key: "ingredients",
      width: 200,
      render: (e) => {
        return (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {e}
              </div>
            }
          >
            <div className="product_description">{e || ""}</div>
          </Popover>
        );
      },
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      width: 200,
    },
    {
      title: "Marketplace ID",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      width: 100,
    },

    {
      title: "Created At",
      align: "left",
      width: 180,
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(text.created_at * 1000)).format(
                "MM/DD/YYYY hh:mm A"
              )}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(text.created_at)})
            </span>
          </div>
        );
      },
    },

    {
      title: "Updated At",
      align: "left",
      width: 180,
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(parseInt(text.updated_at) * 1000)).format(
                "MM/DD/YYYY hh:mm A"
              )}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(parseInt(text.updated_at))})
            </span>
          </div>
        );
      },
    },
  ];
  const [marketplaceImport, setMarketplaceImport] = useState({});
  const [fileList, setFileList] = useState([]);
  const upload = {
    multiple: true,
    name: "UploadedCatalogDataFile[importFile]",
    action: API_URL + `catalog-mapping`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: {
      // region: marketplaceImport?.region,
      // sales_channel: marketplaceImport?.sales_channel,
    },
    onChange(info) {
      const { status } = info.file;

      if (status === "done") {
        if (info?.file?.response?.status) {
          setLoading(true);
          GetListCatalogAction({
            page: page,
            perPage: pageSize,
            ...marketplaceSelected,
            ...filterData,
          });
          message.destroy();
          message.success(
            info?.file?.response?.message || info?.file?.response?.error
          );
          setFileList([]);
        } else {
          message.destroy();
          message.error(
            info?.file?.response?.error || info?.file?.response?.message
          );
        }
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      setFileList(
        info.fileList.filter((file) => !!file.status && file.status !== "done")
      );
    },
    fileList,
    onDrop(e) {},
  };

  const filterTable = (data) => {
    setLoading(true);
    GetListCatalogAction(
      data || {
        page: page,
        perPage: pageSize,
        ...marketplaceSelected,
        ...filterData,
      }
    );
  };

  const [configModal, setConfigModal] = useState(false);
  useEffect(() => {
    if (UpdateCatalogConfigResponse?.status === true) {
      message.destroy();
      message.success(UpdateCatalogConfigResponse?.message);
      setConfigModal(false);
      fakeActionCatalog("UpdateCatalogConfigResponse");
    } else if (UpdateCatalogConfigResponse?.status === false) {
      message.destroy();
      message.error(UpdateCatalogConfigResponse?.message);
      setConfigLoading(true);
      GetListCatalogConfigAction();
      fakeActionCatalog("UpdateCatalogConfigResponse");
    }
  }, [UpdateCatalogConfigResponse]);

  const updateConfig = () => {
    message.destroy();
    message.loading("Loading...", 0);

    UpdateCatalogConfigAction(configList);
  };
  const getColumns = () => {
    const filteredColumns = () => {
      return columns
        .map((d) => {
          const index = configList?.filter((e) => e?.title === d?.title);
          if (index !== -1) {
            return {
              ...d,
              status: index?.[0]?.status,
              fixed: index?.[0]?.sticky ? "left" : "",
            };
          }
          return { ...d };
        })
        .filter((item) => item.status !== false)
        .sort((a, b) =>
          a.fixed === "left" && b.fixed !== "left"
            ? -1
            : a.fixed !== "left" && b.fixed === "left"
            ? 1
            : 0
        );
    };

    return filteredColumns();
  };
  const getColumnsChild = () => {
    const filteredColumns = () => {
      return columnsChild
        .map((d) => {
          const index = configList?.filter((e) => e?.title === d?.title);
          if (index !== -1) {
            return {
              ...d,
              status: index?.[0]?.status,
              fixed: index?.[0]?.sticky ? "left" : "",
            };
          }
          return { ...d };
        })
        .filter((item) => item.status !== false)
        .sort((a, b) =>
          a.fixed === "left" && b.fixed !== "left"
            ? -1
            : a.fixed !== "left" && b.fixed === "left"
            ? 1
            : 0
        );
    };

    return filteredColumns();
  };

  useEffect(() => {
    setColumnsList(getColumns());
    setColumnsListChild(getColumnsChild());
  }, [configList, page, pageSize, sign]);

  const expandedRowRender = (record) => {
    return (
      <Table
        dataSource={record?.manual_data || []}
        columns={columnsListChild}
        pagination={false} // Remove pagination if you don't need it
        scroll={{ x: "max-content" }}
      />
    );
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [current, setCurrent] = useState(0);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (checkPermission(contextValue, "catalogue", "view")) {
    return (
      <Result
        status="403"
        title="403"
        message="Sorry, you are not authorized to access this page."
      />
    );
  }
  if (childASINUpdateFlag || importView) {
    return (
      <ChildUpdate
        selectedRow={selectedRow}
        marketplaceSelected={marketplaceSelected}
        visible={childASINUpdateFlag || importView}
        {...props}
        StartLoading={() => setUpdateLoading(true)}
        updateLoading={updateLoading}
        onClose={() => {
          setChildASINUpdateFlag(false);
          setImportView(false);
          setCurrent(0);
          setSelectedRow({});
        }}
        setCurrent={setCurrent}
        current={current}
        form={form}
      />
    );
  }
  return (
    <Wrapper className="p-5 fadeInBottom">
      <div className="d-flex gap-3 justify-content-between flex-wrap mb-5 fix-over">
        <div />
        <div className="d-flex flex-wrap gap-3">
          <div className="fix-over">
            <Select
              placeholder="Category"
              style={{ width: "200px" }}
              size="large"
              allowClear
              options={categoryList}
              filterOption={(input, option) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
              showSearch
              loading={categoryLoading}
              value={selectedCategorys?.category || null}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(e) => {
                filterTable({
                  page: page,
                  perPage: pageSize,
                  ...marketplaceSelected,
                  ...filterData,
                  ...selectedCategorys,
                  category: e,
                });
                setSubCategoryLoading(true);
                GetSubCategoryAction({
                  ...marketplaceSelected,
                  category: e,
                });
                setSelectedCategorys({
                  ...selectedCategorys,
                  subCategory: null,
                  category: e,
                });
              }}
            />
          </div>
          <div className="fix-over">
            <Select
              placeholder="Sub Category"
              style={{ width: "200px" }}
              filterOption={(input, option) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
              onDropdownVisibleChange={(e) => {
                if (e && !selectedCategorys?.category) {
                  message.destroy();
                  message.warning('Please select "Category" first');
                }
              }}
              showSearch
              className="ms-3 me-3"
              size="large"
              options={subCategoryList}
              loading={subCategoryLoading}
              allowClear
              value={selectedCategorys?.subCategory || null}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(e) => {
                filterTable({
                  page: page,
                  perPage: pageSize,
                  ...marketplaceSelected,
                  ...filterData,
                  ...selectedCategorys,
                  subCategory: e,
                });
                setSelectedCategorys({
                  ...selectedCategorys,
                  subCategory: e,
                });
              }}
            />
          </div>
          {Object.keys(filterData)?.map((d, i) => {
            return (
              <Input
                size="large"
                className="w-150px me-3"
                placeholder={`${d}`}
                value={filterData?.[d]}
                onPressEnter={() => filterTable()}
                onChange={(e) => {
                  setFilterData({ ...filterData, [d]: e.target.value });
                }}
              />
            );
          })}
          <Select
            className="w-200px me-3"
            placeholder="Select filter type"
            getPopupContainer={(trigger) => trigger.parentNode}
            size="large"
            maxTagCount={"responsive"}
            onChange={(e) => {
              const nullObject = {};

              for (const key of e) {
                nullObject[key] = filterData?.[key] || null;
              }
              if (e?.length < selectedFilter?.length) {
                filterTable({
                  page: page,
                  perPage: pageSize,
                  ...marketplaceSelected,
                  ...nullObject,
                });
              }

              setFilterData(nullObject);
              setSelectedFilter(e);
            }}
            value={selectedFilter}
            mode="multiple"
            options={[
              {
                label: "SKU",
                value: "sku",
              },
              {
                label: "ASIN",
                value: "asin",
              },
              {
                label: "EAN",
                value: "ean",
              },
              // {
              //   label: "Child SKU",
              //   value: "child_sku",
              // },
              // {
              //   label: "Child ASIN",
              //   value: "child_asin",
              // },
              // {
              //   label: "Brand",
              //   value: "brand",
              // },
            ]}
          />
          <Select
            className="w-225px me-3"
            size="large"
            getPopupContainer={(target) => target.parentNode}
            options={marketplaceList}
            onChange={(_, e) => {
              setSubCategoryLoading(true);
              GetSubCategoryAction({
                region: e?.region,
                sales_channel: e?.sales_channel,
              });
              setCategoryLoading(true);
              GetCategoryAction({
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e.account_type,
              });
              setMarketplaceSelected({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
              setLoading(true);
              GetListCatalogAction({
                page: page,
                perPage: pageSize,
                ...filterData,
                ...{
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                },
              });
            }}
            value={marketplaceSelected?.marketplace_id}
            loading={marketplaceLoading}
            // value={selectedMarketplace}
            placeholder="Select Marketplace"
          />

          <Button
            disabled={checkPermission(contextValue, "catalog", "add")}
            type="primary"
            size="large"
            style={{ fontSize: "16px" }}
            onClick={() => setImportView(true)}
          >
            Add
          </Button>
          <Dropdown
            disabled={checkPermission(contextValue, "catalog", "import")}
            trigger={["click"]}
            getPopupContainer={(trigger) => trigger.parentNode}
            menu={{
              items: [],
            }}
            placement="bottomRight"
            dropdownRender={(menu) => (
              <div
                style={{
                  backgroundColor: "rgb(255, 255, 255)",
                  borderRadius: "8px",
                  paddingBottom: "10px",
                  padding: "10px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.08) 0px 6px 16px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.05) 0px 9px 28px 8px",
                }}
              >
                <div className=" py-5">
                  <div className="fs-5 text-dark fw-bold d-flex">
                    Upload File{" "}
                    <a
                      href="/MarketForce.xlsx"
                      className="info"
                      style={{ marginLeft: "5px" }}
                      download
                    >
                      <span className="svg-icon svg-icon-muted">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <rect
                            x={11}
                            y={17}
                            width={7}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11 17)"
                            fill="currentColor"
                          />
                          <rect
                            x={11}
                            y={9}
                            width={2}
                            height={2}
                            rx={1}
                            transform="rotate(-90 11 9)"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                {/* <div>
                    <Select
                      className="w-225px mb-5"
                      size="middle"
                      allowClear
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      options={marketplaceList}
                      loading={marketplaceLoading}
                      onChange={(e, _) => {
                        setMarketplaceImport({
                          sales_channel: _?.sales_channel,
                          region: _?.region,
                        });
                      }}
                      placeholder="Select Marketplace"
                    />
                  </div> */}
                <div className="separator border-gray-200" />
                <Dragger {...upload}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text px-4">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single upload.
                  </p>
                </Dragger>
              </div>
            )}
          >
            <button
              onClick={(e) => e.preventDefault()}
              type="button"
              className="btn  fs-7 btn-primary btn-active-light-dark ms-3 fw-bold"
              style={{ fontSize: "16px" }}
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
            >
              Import
            </button>
          </Dropdown>
          <Icons
            style={{
              opacity: checkPermission(contextValue, "catalogue", "config")
                ? 0.5
                : 1,
              PointerEvent: checkPermission(contextValue, "catalogue", "config")
                ? "none"
                : "auto",
            }}
            onClick={() => {
              if (checkPermission(contextValue, "catalogue", "config")) {
                return;
              }
              message.destroy();
              if (configLoading) return message.warning("Config not found");
              setConfigModal(true);
            }}
            type="setting"
            className="ms-3 cursor-pointer mt-1"
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body pt-5">
          <div className="mb-5">
            <Tag color="blue" className="fs-6">
              <b>Parent SKU&nbsp;:&nbsp;</b>
              {counts?.parent_count || 0}
            </Tag>
            <Tag color="magenta" className=" fs-6">
              <b>Child SKU&nbsp;:&nbsp;</b>
              {counts?.child_count || 0}
            </Tag>
            <Tag color="cyan" className=" fs-6">
              <b>Single SKU&nbsp;:&nbsp;</b>
              {counts?.variation_count || 0}
            </Tag>
          </div>
          {loading ? (
            <TableLoading
              id="test-table"
              columns={[1, 2, 3, 4, 5]}
              checkBoxVal={true}
              actions={[1, 2]}
            />
          ) : (
            <Table
              dataSource={formatData(list)?.map((d, i) => ({ ...d, key: i }))}
              rowKey={"id"}
              // rowSelection={rowSelection}
              loading={loading}
              // sticky
              expandable={{
                expandedRowRender,
                expandIcon: ({ expanded, onExpand, record }) =>
                  record.relationship_type?.toLowerCase() === "parent" ? (
                    <a onClick={(e) => onExpand(record, e)}>
                      {expanded ? "-" : "+"}
                    </a>
                  ) : null,
              }}
              columns={[
                {
                  title: "Actions",
                  fixed: "left",
                  width: 50,
                  render: (_, __, i) => {
                    console.log(_, __, i);

                    return (
                      <div className="d-flex">
                        <div
                          className="me-3 cursor-pointer"
                          onClick={async () => {
                            const confirmed = await modal.confirm(config);
                            if (confirmed) {
                              const obj = {
                                sales_channel:
                                  marketplaceSelected?.sales_channel,
                                account_type: marketplaceSelected?.account_type,
                                manual_catalog_id: [_?.id],
                              };
                              CreateFeedListingLogs(obj);
                              setUploadLoading(true);
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 730.93 753.06"
                            style={{
                              enableBackground: "new 0 0 730.93 753.06",
                            }}
                            xmlSpace="preserve"
                            width={25}
                          >
                            <g>
                              <path d="M610.91,470.57c-4.03-6.29-8.52-12.31-13.09-18.22c-17.96-23.2-26.02-49.49-25.91-78.72c0.24-61.81,0.1-123.63,0.18-185.44   c0.02-20.98-0.51-41.9-4.08-62.66c-6.09-35.42-22.73-64.53-51.7-86.07c-30.5-22.68-65.82-32.48-102.84-37.51   c-5.15-0.7-10.45-0.14-15.45-1.95h-45.98c-0.31,1.06-1.19,0.94-2,1c-28.59,1.98-56.43,7.57-83.14,18.09   C236.23,31.18,209.64,49,189.06,75.21c-17.66,22.49-28.04,48.17-33.77,75.93c-2.37,11.46,4.25,21,16.03,22.56   c13.01,1.72,26.1,2.82,39.15,4.21c17.67,1.89,35.35,3.73,53,5.78c4.61,0.53,8.57-0.5,12.09-3.3c4.62-3.66,7.25-8.59,8.45-14.27   c3.92-18.59,12.72-34.17,28.53-45.09c20.14-13.91,42.73-17.58,66.5-13.9c27.04,4.19,44.49,21.11,50.08,47.12   c4.25,19.76,2.46,39.77,2.94,59.69c0.06,2.7-1.45,2.82-3.44,3.03c-29.24,3.04-58.51,5.74-87.64,9.87   c-27.42,3.89-54.54,8.99-80.73,18.2c-40.53,14.26-74.58,37.39-96.84,74.9c-28.49,48.01-31.86,99.57-16.61,152.21   c16.69,57.61,62.58,89.25,117.37,95.8c18.46,2.21,36.84,1.54,55.29,0c22.36-1.86,43.73-7.2,63.8-17.23   c24.56-12.27,44.9-30.12,64.25-49.23c4.36-4.3,4.46-4.37,7.71,0.67c14.35,22.2,30.57,42.77,51.43,59.32   c1.83,1.45,3.68,2.71,5.96,3.42c8.88,2.8,16.24-0.11,22.9-5.87c23.8-20.59,47.6-41.18,71.41-61.76   C616.28,489.17,617.66,481.11,610.91,470.57z M432.04,300.13c-0.06,8.32-0.02,16.65-0.03,24.98c0.1,21.29,0.16,42.63-5.03,63.44   c-7.28,29.24-20.81,54.6-47.64,70.58c-17.37,10.34-36.13,12.86-55.47,6.33c-19-6.42-30.11-20.71-35.55-39.31   c-6.32-21.57-5.76-43.31,1.41-64.65c9.07-26.99,29.1-42.91,54.83-52.4c27.32-10.07,55.89-12.24,84.73-12.33   C432.12,296.76,432.06,298.13,432.04,300.13z" />
                              <path
                                style={{
                                  fill: "#FE9800",
                                }}
                                d="M363.82,753.03c-61.85,0.49-115.35-11.25-166.77-32.58c-69.78-28.95-130.6-71.11-182.58-125.95   c-3.32-3.5-6.43-7.21-9.5-10.94c-5.27-6.41-6.22-10.42-3.46-13.69c3.3-3.93,8.13-3.75,15.35,0.58   c15.52,9.32,30.96,18.8,46.59,27.94c44.47,26,90.48,48.7,139.57,64.8c33.17,10.88,67.07,18.53,101.72,22.5   c33.47,3.84,67.03,4.19,100.67,1.43c80.19-6.58,155.52-30.29,228.11-63.89c4.05-1.88,8-4.04,12.45-4.93   c7.5-1.5,12.51,3.83,10.44,11.13c-1.78,6.29-5.7,11.21-10.2,15.72c-21.58,21.61-46.57,38.59-73.12,53.36   c-39.7,22.08-82.01,37.23-126.53,46.04C417.3,750.32,387.73,753.39,363.82,753.03z"
                              />
                              <path
                                style={{
                                  fill: "#FE9800",
                                }}
                                d="M670.52,560.57c18.68,0.13,31.79,0.49,44.57,4.11c13.55,3.84,16.59,6.79,15.69,20.54   c-2.68,40.86-16.17,77.41-45,107.41c-2.99,3.12-6.17,6.08-10.5,7.36c-4.36,1.28-6.97-0.74-6.23-5.22c0.4-2.41,1.45-4.74,2.4-7.03   c8.58-20.67,16.34-41.62,21.04-63.57c1.29-6.03,1.84-12.12,1.77-18.31c-0.09-6.95-3.56-11.05-9.9-13.33   c-6.98-2.51-14.24-3.35-21.52-3.6c-18.84-0.65-37.53,1.49-56.21,3.62c-4.79,0.55-9.6,0.88-14.41,1.25   c-1.94,0.15-3.63-0.4-4.64-2.26c-1.09-2,0.02-3.57,1.13-4.99c3.04-3.89,7.16-6.43,11.47-8.65   C624.01,565.68,649.3,559.9,670.52,560.57z"
                              />
                            </g>
                          </svg>
                        </div>
                        <span
                          onClick={() => {
                            setChildASINUpdateFlag(true);
                            setSelectedRow({ ...__, parent_sku: __?.sku });
                          }}
                          className="cursor-pointer svg-icon svg-icon-primary svg-icon-1"
                        >
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                              fill="currentColor"
                            />
                            <path
                              d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                              fill="currentColor"
                            />
                            <path
                              d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <div>
                          <Popconfirm
                            title="Are you sure to delete this catalog?"
                            placement="left"
                            cancelText="No"
                            okText="Yes"
                            onConfirm={() => {
                              DeleteCatalogManualAction(__?.id);
                              message.destroy();
                              message.loading("Loading...", 0);
                            }}
                          >
                            <Icon
                              className="ms-3"
                              style={{ color: "#ff6c6c", cursor: "pointer" }}
                              width={24}
                              icon="material-symbols:delete"
                            />
                          </Popconfirm>
                        </div>
                      </div>
                    );
                  },
                },
                {
                  title: "Status",
                  dataIndex: "status",
                  key: "status",
                  align: "center",
                  width: 100,
                  render: (e) => {
                    return (
                      <Tag color="blue" bordered={false}>
                        Draft
                      </Tag>
                    );
                  },
                },
                ...columnsList?.map((item) => {
                  Object.keys(item).forEach((key) => {
                    if (item[key] === "") {
                      delete item[key];
                    }
                  });
                  return item;
                }),
              ]}
              pagination={false} // Remove pagination if you don't need it
              scroll={{ x: "max-content" }}
            />
          )}
          <Pagination
            loading={loading || list?.length === 0}
            pageSize={pageSize}
            pageSizeStatus={false}
            page={page}
            totalPage={totalPage}
            onPerPage={onPerPage}
            onPageNo={onPageNo}
          />
        </div>
      </div>
      {importView && (
        <AddItemModal
          visible={importView}
          onCancel={() => setImportView(false)}
          onAdd={() => {}}
          getList={() => {
            setLoading(true);
            GetListCatalogAction({
              page: page,
              perPage: pageSize,
              ...marketplaceSelected,

              ...filterData,
            });
          }}
          {...props}
        />
      )}
      {configModal && (
        <Config
          list={configList}
          setList={setConfigList}
          isOpen={configModal}
          onClose={() => {
            setConfigModal(false);
            setConfigLoading(true);
            GetListCatalogConfigAction();
          }}
          onSubmit={() => {
            updateConfig();
          }}
        />
      )}
      {childASINUpdateFlag && (
        <ChildUpdate
          selectedRow={selectedRow}
          visible={childASINUpdateFlag}
          {...props}
          onClose={() => setChildASINUpdateFlag(false)}
          StartLoading={() => setUpdateLoading(true)}
          updateLoading={updateLoading}
        />
      )}
      {contextHolder}
    </Wrapper>
  );
};

export default Catalogue;
